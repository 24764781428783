.root {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  background: #f7faff;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  padding-bottom: 70px;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
}

.title {
  margin-top: 72px;
  margin-bottom: 64px;
  font-size: 42px;
  font-weight: 500;
  color: #333333;
}

.form {
  & > *:not(:first-child) {
    margin-top: 32px;
  }

  .buttonWrap {
    margin-top: 77px;
  }
}

.commonBtn {
  width: 638px;
  height: 82px;
  background: #5774FF;
  box-shadow: 0px 10px 12px 0px rgba(0, 87, 253, 0.25);
  border-radius: 41px;
  opacity: 0.3;
  pointer-events: none;
  cursor: not-allowed;
}

.activeBtn {
  pointer-events: auto;
  cursor: pointer;
  opacity: 1;
}
