@mixin setFontSize {
  font-size: 30px;
}

@mixin setWidth {
  width: 100%;
}

.root {
  display: flex;
  flex-direction: column;
  @include setWidth();

  label {
    // color: #ffffff;
    color: #333333;
    // font-size: 30px;
    // height: 56px;
    padding-left: 42px;
    display: flex;
    align-items: center;
    @include setWidth();
    @include setFontSize();
    margin-bottom: 18px;
    display: flex;
    align-items: center;

    img {
      width: 30px;
      margin-right: 20px;
    }

    .required {
      color: #FF0000;
      margin-left: 6px;
    }
  }

  // .labelRequired {
  //   &:after {
  //     display: inline-block;
  //     content: "*";
  //     color: #ffffff;
  //     margin-left: 6px;
  //   }
  // }

  input {
    // @include setWidth();
    @include setFontSize();
    width: 638px;
    height: 82px;
    background: #ffffff;
    border-radius: 41px;
    border: 1px solid #d9d9d9;

    // color: #D9D9D9;

    color: black;
    // border-radius: 8px;
    // padding: 12px 26px;
    padding: 20px 40px;
    line-height: 56px;
    box-sizing: border-box;
    outline: none;
    box-shadow: none;
    appearance: none;
    -webkit-appearance: none;
    // border: none;

    &::-webkit-input-placeholder {
      color: #d9d9d9;
      font-weight: 400;
    }

    &:-moz-placeholder {
      color: #d9d9d9;
      font-weight: 400;
    }

    &::-moz-placeholder {
      color: #d9d9d9;
      font-weight: 400;
    }

    &:-ms-input-placeholder {
      color: #d9d9d9;
      font-weight: 400;
    }
  }

  .errorInput {
    border: 1px solid #ff0000;
  }
}

.error {
  font-size: 24px;
  color: #ff0000;
  line-height: 33px;
  width: 100%;
  text-align: center;
  margin-top: 18px;
}
