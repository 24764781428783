$commonHeight: 100%;

@mixin gradientBG {
  background: linear-gradient(152deg, #0B2F96 0%, #1961AF 100%);
}

@mixin transparentBG {
  background: transparent;
}

.root {
  // position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: $commonHeight;
  width: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  // position: relative;
  outline: none;
  border: none;
  overflow: hidden;
  // @include gradientBG();
  border: none;

  .slide {
    width: 100%;
    height: $commonHeight;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
    overflow: hidden;
    color: black;
    position: relative;
    border: none;
    // @include transparentBG();
  }

  .page {
    display: flex;
    width: 100%;
    height: $commonHeight;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
    border: none;
    overflow: hidden;
    padding-top: 100px;
    // background-image: url("../../images/invitation/bg2.png");
    // background-size: 100% 100%;
  }
}

.homepage {
  display: flex;
  flex-direction: column;
  @include gradientBG();

  .titleImg {
    margin-top: 114px;
    margin-left: 66px;

    font-size: 64px;
    font-weight: 600;
    color: #ffffff;
  }

  .subTitle {
    margin-top: 40px;
    margin-left: 66px;
    color: #ffffff;
    width: 583px;
    font-size: 36px;
    color: #ffffff;
    line-height: 50px;
  }

  .applyButton {
    margin-left: 66px;
    z-index: 99;
    margin-top: 52px;
    // margin-bottom: 196px;
  }

  .page1Bottom {
    margin: 0 auto;
    margin-top: 300px;
    width: 90%;
  }

  @media screen and (max-height: 700px) {
    .page1Bottom {
      margin-top: 10vh;
    }
  }
}

.icon-arrow {
  width: 100%;
  position: fixed;
  bottom: 40px;
  z-index: 10;
}


.horizontalSlide {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  flex-shrink: 0;

  img {
    height: 360px;
    margin-top: 120px;
    margin-bottom: 50px;
  }

  &_title {
    font-size: 32px;
    font-weight: 500;
    color: #000000;
  }

  &_info {
    margin-top: 15px;
    width: 582px;
    // width: 396px;
    font-size: 28px;
    color: #666666;
    line-height: 45px;
  }
}

.customerDots {
  width: 100%;
  margin-top: 58px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  span {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: red;
    will-change: opacity;
    transition: opacity 0.3s;
    background-color: #1a478a;
    opacity: 0.25;

    &:not(:last-child) {
      margin-right: 14px;
    }
  }

  .dotActive {
    opacity: 1;
  }
}

.infopage {}

.authpage {
  .imgTopText {
    margin-top: 80px;
    font-size: 28px;
    color: #666666;
    line-height: 40px;
  }

  .users {
    margin-top: 40px;
    .row {
      display: flex;
      justify-content: center;
    }

    .user-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 40px;
      margin-left: 20px;
      margin-right: 20px;

      img {
        height: 160px;
      }

      span {
        margin-top: 10px;
        font-size: 28px;
        font-weight: 500;
        color: #000000;
        line-height: 45px;
      }
    }
  }

  .authBg {
    margin-top: 138px;
    margin-bottom: 40px;
    width: 80%;
  }



  .authtitle {
    font-size: 40px;
    font-weight: 500;
    color: #333333;
    line-height: 45px;
    text-align: center;
  }

  .subTitle {
    margin-top: 120px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 576px;

    &>div {
      width: 50%;
      display: flex;
      align-items: flex-start;
      align-items: center;
      margin-bottom: 32px;


      font-size: 32px;
      font-weight: 500;
      color: #000000;

      img {
        height: 30px;
        margin-right: 16px;
      }
    }
  }
}

.infopage,
.authpage,
.brandpage,
.contactpage,
.resultpage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: #fff;
  background-image: url("../../images/home/logoBG.png");
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: 593px auto;

  .topRow {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 56px;
    margin-bottom: 64px;
    padding-left: 65px;
    padding-right: 50px;
    box-sizing: border-box;
  }

  .logo1 {
    width: 210px;
  }

  .logo2 {
    width: 284px;
  }

  .title {
    width: 702px;
    align-self: flex-end;
  }



}