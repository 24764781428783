$mainColor: #5774FF;

.icon {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border: 3px solid $mainColor;
  position: relative;
  flex-shrink: 0;

  &::after {
    content: "";
    margin: 0;
    padding: 0;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: $mainColor;
    box-sizing: border-box;
    flex-shrink: 0;
  }
}

@keyframes light {
  0% {
    opacity: 0.3;
  }

  100% {
    opacity: 1;
  }
}
