$commonHeight: 100%;

@mixin gradientBG {
  background: linear-gradient(152deg, #0B2F96 0%, #1961AF 100%);
}

@mixin transparentBG {
  background: transparent;
}

.root {
  // position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: $commonHeight;
  width: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  // position: relative;
  outline: none;
  border: none;
  overflow: hidden;
  // @include gradientBG();
  border: none;

  .slide {
    width: 100%;
    height: $commonHeight;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
    overflow: hidden;
    color: black;
    position: relative;
    border: none;
    // @include transparentBG();
  }

  .page {
    display: flex;
    width: 100%;
    height: $commonHeight;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
    border: none;
    overflow: hidden;
    padding-top: 100px;
    // background-image: url("../../images/invitation/bg2.png");
    // background-size: 100% 100%;
  }
}

.homepage {
  display: flex;
  flex-direction: column;
  @include gradientBG();

  .titleImg {
    margin-top: 114px;
    margin-left: 66px;

    font-size: 64px;
    font-weight: 600;
    color: #ffffff;
  }

  .subTitle {
    margin-top: 40px;
    margin-left: 66px;
    color: #ffffff;
    width: 583px;
    font-size: 36px;
    color: #ffffff;
    line-height: 50px;
  }

  .applyButton {
    margin-left: 66px;
    z-index: 99;
    margin-top: 52px;
    // margin-bottom: 196px;
  }

  .page1Bottom {
    margin: 0 auto;
    margin-top: 300px;
    width: 90%;
  }

  @media screen and (max-height: 700px) {
    .page1Bottom {
      margin-top: 10vh;
    }
  }
}

.icon-arrow {
  width: 100%;
  position: fixed;
  bottom: 40px;
  z-index: 10;
}

.infoTitle {
  margin-top: 145px;
  display: flex;
  justify-content: center;
  align-items: center;

  &>div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .titleIconWrap {
    margin-right: 20px;
  }

  .page2Title {
    font-size: 42px;
    font-weight: 500;
    color: #333333;
    line-height: 45px;
  }
}

.mt97 {
  margin-top: 97px;
}

.mt174 {
  margin-top: 174px;
}

.mt170 {
  margin-top: 170px;
}

.horizontalSlide {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  flex-shrink: 0;

  img {
    height: 360px;
    margin-top: 120px;
    margin-bottom: 50px;
  }

  &_title {
    font-size: 32px;
    font-weight: 500;
    color: #000000;
  }

  &_info {
    margin-top: 15px;
    width: 582px;
    // width: 396px;
    font-size: 28px;
    color: #666666;
    line-height: 45px;
  }
}

.customerDots {
  width: 100%;
  margin-top: 58px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  span {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: red;
    will-change: opacity;
    transition: opacity 0.3s;
    background-color: #1a478a;
    opacity: 0.25;

    &:not(:last-child) {
      margin-right: 14px;
    }
  }

  .dotActive {
    opacity: 1;
  }
}

.infopage {
  .card {
    margin-bottom: 45px;
    width: 622px;
    height: 204px;
    background: #ffffff;
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 0 10px 8px rgba(154, 195, 255, 0.15);

    &>img:first-child {
      width: 194px;
      margin-right: 30px;
      margin-left: 30px;
    }

    .middleInfo {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      &>div:nth-child(1) {
        font-size: 36px;
        font-weight: 500;
        color: #333333;
        line-height: 45px;
      }

      &>div:nth-child(2) {
        font-size: 28px;
        color: #666666;
        line-height: 42px;
      }
    }

    &>img:last-child {
      width: 32px;
      height: 32px;
      margin-right: 30px;
    }
  }
}

.authpage {
  .authBg {
    // width: 633px;
    height: 360px;
  }

  .imgTopText {
    margin-top: 60px;
    margin-bottom: 70px;
    width: 590px;
    font-size: 28px;
    color: #666666;
    line-height: 40px;
  }

  .authtitle {
    font-size: 40px;
    font-weight: 500;
    color: #333333;
    line-height: 45px;
    margin-top: 11px;
    text-align: center;
  }

  .subTitle {
    margin-top: 100px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 576px;

    &>div {
      width: 50%;
      display: flex;
      align-items: flex-start;
      align-items: center;
      margin-bottom: 32px;


      font-size: 32px;
      font-weight: 500;
      color: #000000;

      img {
        height: 30px;
        margin-right: 16px;
      }
    }
  }
}

.brandpage {
  .brandLogo {
    width: 658px;
  }
}

.contactpage {
  .contactItem {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .contactItem:nth-child(3),
  .contactItem:nth-child(4) {
    margin-top: 70px;
  }

  .infoIcon {
    width: 70px;
    height: 70px;
    margin-bottom: 10px;
  }

  &>div {
    font-size: 28px;
    color: #333333;
    line-height: 42px;
  }

  .copyright {
    position: absolute;
    bottom: 44px;
    text-align: center;
    font-size: 24px;
    color: #b8bac0;
    line-height: 42px;
  }
}

.infopage,
.authpage,
.brandpage,
.contactpage,
.resultpage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: #fff;
  background-image: url("../../images/home/logoBG.png");
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: 593px auto;

  .topRow {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 56px;
    margin-bottom: 64px;
    padding-left: 65px;
    padding-right: 50px;
    box-sizing: border-box;
  }

  .logo1 {
    width: 210px;
  }

  .logo2 {
    width: 284px;
  }

  .title {
    width: 702px;
    align-self: flex-end;
  }

  .cup {
    position: relative;
    height: 560px;
    align-self: flex-end;
    justify-self: flex-end;
  }

  .infomation {
    width: 625px;

    &>div {
      font-size: 32px;
      font-weight: 400;
      color: #ffffff;
      line-height: 56px;
    }

    &>div:not(:nth-child(1)) {
      margin-top: 56px;
    }

    &>div:last-child {
      display: flex;
      justify-content: flex-end;
    }
  }

  .addresss>div {
    font-weight: 700;
  }

  .resultTitle {
    width: 652px;
    height: 120px;
    font-size: 40px;
    font-weight: 600;
    color: #ffffff;
    line-height: 60px;
    display: flex;
    flex-direction: column;
    align-content: stretch;
    margin-bottom: 25px;

    &>div {
      text-align: center;
    }
  }

  .resultInfo {
    width: 637px;
    height: 48px;
    font-size: 28px;
    font-weight: 400;
    color: #ffffff;
    line-height: 48px;
    margin-top: 33px;
    text-align: center;
  }
}