.icon-up-arrow {
    display: flex;
    justify-content: center;
}

@keyframes bounce-down {
    25% {
        transform: translateY(-3px);
    }

    50% {
        transform: translateY(0);
    }

    75% {
        transform: translateY(3px);
    }

    100% {
        transform: translateY(0);
    }
}

.animate-bounce-down {
    animation: bounce-down 1s linear infinite;
    width: 40px;
    height: 40px;
}