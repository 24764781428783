.root {
  width: fit-content;
  cursor: pointer;
  .displayButton {
    width: 252px;
    height: 72px;
    background: #5774FF;
    border-radius: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    color: #ffffff;
  }

  .displayButtonHeater {
    width: 164px;
    height: 54px;
    background: #ffffff;
    border-radius: 27px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28px;
    color: #04153f;
  }
}
