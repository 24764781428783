.root {
  width: 100%;
  font-size: 32px;
  font-weight: 500;
  color: #ffffff;
  line-height: 1;
  // letter-spacing: 32px;
  height: 90px;
  border-radius: 45px;
  outline: none;
  border: none;
}
