.srcoll-top {
    background: linear-gradient(152deg, #1A5EAE 0%, #0B3297 100%);
    border-radius: 20px 0px 0px 20px;

    .line {
        width: 100px;
        height: 2px;
        background-color: rgba(#ffffff, 0.5);
        margin: auto;
    }

    .content {
        width: 140px;
        height: 140px;

        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;


        font-size: 24px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        white-space: nowrap;
        cursor: pointer;

        &-1 {
            border-top-left-radius: 20px;
        }

        &-3 {
            border-bottom-left-radius: 20px;
        }

        &:active {
            background: rgba(0, 0, 0, 0.4);
        }

        img {
            height: 46px;
            margin-bottom: 12px;
        }
    }
}

.content-relative {
    position: relative;

    .content-phone {
        position: absolute;
        top: 40px;
        right: 150px;

        display: flex;
        align-items: center;

        .arrow-content {
            box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
            display: block;
            width: 12px;
            height: 12px;
            background-color: #fff;
            content: "";
            pointer-events: auto;
            box-shadow: 3px -3px 7px #00000012;
            transform: translate(-6px) rotate(45deg);
        }

        .tel {
            border-radius: 6px;
            padding: 20px 37px 20px 20px;
            background: linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 100%);
            box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);

            font-size: 28px;
            line-height: 28px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #0F3D9D;
            white-space: nowrap;
        }
    }


}