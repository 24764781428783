body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.delay100 {
  animation-delay: 100ms !important;
}
.delay200 {
  animation-delay: 200ms !important;
}
.delay300 {
  animation-delay: 300ms !important;
}
.delay400 {
  animation-delay: 400ms !important;
}
.delay500 {
  animation-delay: 500ms !important;
}
.delay600 {
  animation-delay: 600ms !important;
}
.delay700 {
  animation-delay: 700ms !important;
}
.delay800 {
  animation-delay: 700ms !important;
}
.delay900 {
  animation-delay: 700ms !important;
}
.delay1 {
  animation-delay: 1s !important;
}

.fadeInFromBottom {
  will-change: transform;
  animation: ani 0.5s both;
}

@keyframes ani {
  0% {
    opacity: 0;
    transform: translate3d(0, 150%, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
