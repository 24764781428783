$pointWidth: 6px;

.icon {
  display: grid;
  grid-template-columns: repeat(3, 33.33%);
  grid-template-rows: repeat(3, 33.33%);
  grid-row-gap: 4px;
  grid-column-gap: 4px;

  & > span {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    display: inline-block;
    background-color: #2c62a9;
    flex-shrink: 0;
  }

  & > span:nth-child(1) {
    animation: light 0.5s linear infinite alternate;
  }

  & > span:nth-child(4),
  & > span:nth-child(5) {
    animation: light 0.5s .2s linear infinite alternate;
  }
  & > span:nth-child(7),
  & > span:nth-child(8),
  & > span:nth-child(9) {
    animation: light 0.5s .4s linear infinite alternate;
  }

  & > span:nth-child(2),
  & > span:nth-child(3),
  & > span:nth-child(6) {
    animation: none;
    opacity: 0;
  }
}

@keyframes light {
  0% {
    opacity: 0.3;
  }

  100% {
    opacity: 1;
  }
}
