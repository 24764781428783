$headerHeight: 108px;
$contentHeight: calc(100% - 108px);
$topIndex: 999;
$commonIndex: 1;

.root {
  // width: 100%;
  // height: 100%;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  // padding-bottom: constant(safe-area-inset-bottom);
  // padding-bottom: env(safe-area-inset-bottom);

  .header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: $topIndex;
    height: $headerHeight;
    box-sizing: border-box;
    width: 100%;
    background: #191919;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px 0 34px;
    flex-shrink: 0;

    .logo {
      width: 154px;
      height: 44px;
      cursor: pointer;
    }

    .headerRight {
      display: flex;
      flex-direction: row;
      align-items: center;

      .headerBtn {
        margin-right: 54px;
      }
    }
  }

  .content {
    flex-shrink: 0;
    min-height: 100%;
    width: 100%;
    z-index: $commonIndex;
    box-sizing: border-box;
    flex-grow: 1;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    // top: $headerHeight;
    // height: $contentHeight;
    // height: 100%;
    // overflow: hidden;
    // background: linear-gradient(152deg, #0B2F96 0%, #1961AF 100%);
    // padding-bottom: constant(safe-area-inset-bottom);
    // padding-bottom: env(safe-area-inset-bottom);
  }

  .menu {
    height: $contentHeight;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    z-index: $topIndex;
    left: 0;
    top: $headerHeight;
    box-sizing: border-box;
    width: 100%;
    transform-origin: top;
    transform: scale(1, 0);
    opacity: 0;
    transition: all 0.2s;

    .menuItem {
      width: 100%;
      height: 108px;
      display: flex;
      align-items: center;
      font-size: 30px;
      color: #333333;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;

      & > div {
        display: flex;
        align-items: center;
        height: 100%;
        width: calc(750px - (72px * 2));
        border-bottom: 1px solid #e8e8e8;
      }
    }

    .activeMenuItem {
      color: #5774FF;
    }
  }

  .showMenu {
    transform: scale(1, 1);
    opacity: 1;
  }
}

.show {
  & > *:nth-child(1) {
    transform-origin: top left;
    transform: rotate(45deg);
    width: 38px;
  }
  & > *:nth-child(2) {
    opacity: 0;
  }
  & > *:nth-child(3) {
    transform-origin: bottom left;
    transform: rotate(-45deg);
    width: 38px;
  }
}

.menuBtn {
  height: 30px;
  width: 40px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  & > * {
    flex-shrink: 0;
    will-change: transform;
    transform: translate3d(0, 0, 0);
    transition: all 0.2s;
    margin: 0;
    padding: 0;
    flex-shrink: 0;
    width: 34px;
    height: 4px;
    background-color: #fff;
    border-radius: 4px;
  }
}
