.root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  position: relative;
  outline: none;
  overflow: auto;
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: #fff;
}

.paragraph {
  width: 623px;
  font-size: 28px;
  color: #333333;
  line-height: 45px;
  flex-shrink: 0;

  &:not(:nth-child(1)) { 
    margin-top: 80px;
  }
}

.content {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 100px;
}
