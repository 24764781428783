.infoTitle {
  margin-top: 135px;
  display: flex;
  justify-content: center;
  align-items: center;
  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .titleIconWrap {
    margin-right: 20px;
  }

  .page2Title {
    font-size: 42px;
    font-weight: 500;
    color: #333333;
    line-height: 45px;
  }
}